import Interop from "../interfaces/Interop";
import { ModuleType } from "../enums/ModuleType";
import { ApplicationColor } from "../models/ApplicationColor";
import { ChatPageNavigation } from "../models/ChatPageNavigation";
import { ClientCredential } from "../models/ClientCredential";
import { ChatPageType } from "../enums/ChatPageType";

export default class WebInterop implements Interop {
    public responseHandlers: Map<string, (value: any) => void> = new Map();

    constructor() {
        this.initMessageListener();
    }

    public initMessageListener() {
        window.addEventListener("message", this.handleMessage);
    }

    FinishedLoading(isFinished: boolean): void {
        console.log("Finished Loading" + isFinished );
    }

    ToogleMenu(): void {
        console.log("Menu Toggled!");
    }

    SlidingGesture(enable: boolean): void {
        console.log("Sliding Gesture: " + enable);
    }
    
    async GetCredentials(): Promise<ClientCredential> {
        const response = await this.sendMessageAndWaitForResponse("GetCredentials", JSON.parse);
        const { UserId, UserToken, TeamId,EventId } = response;
        return new ClientCredential(UserId, UserToken, TeamId, Number.parseInt(EventId));
    }

    async GetColors(): Promise<ApplicationColor> {
        const response = await this.sendMessageAndWaitForResponse("GetColors", JSON.parse);
        const { TopNavBackgroundColor, TopNavForegroundColor } = response;
        return new ApplicationColor(TopNavBackgroundColor, TopNavForegroundColor);
    }

    async GetPageType(): Promise<ChatPageNavigation> {
        const pageTypeResponse = await this.sendMessageAndWaitForResponse("GetPageType", JSON.parse);
        const {ChatPageType , EntityType , EntityId,ChannelHashId} = pageTypeResponse;
        return new ChatPageNavigation(ChatPageType , EntityType , EntityId,ChannelHashId);
    }
    

    GetFontSize(): Promise<Number> {
        return Promise.resolve(100);
    }

    NavigationHandle(enable: boolean): void {
        console.log("Navigation Handle: " + enable);
    }

    PushHandle(ReceiverUserId: string): void {
        ReceiverUserId = ReceiverUserId.split('-')[1];
        console.log("PushHandle", ReceiverUserId);
    }

    public sendMessage(type: string, receiverUserId?: string) {
        const message: { type: string; receiverUserId?: string } = { type };
    
        if (receiverUserId) {
            message.receiverUserId = receiverUserId;
        }
    
        window.parent.postMessage(message, "https://web-dev.ventla.io/");
        window.parent.postMessage(message, "https://vueadmin-dev.ventla.io/");
    }

    public sendMessageAndWaitForResponse<T>(type: string, transform: (data: any) => T): Promise<T> {
        return new Promise((resolve, reject) => {
            const handler = (data: any) => {
                try {
                    resolve(transform(data));
                } catch (error) {
                    reject(error);
                } finally {
                    this.responseHandlers.delete(type);
                }
            };

            this.responseHandlers.set(type, handler);
            this.sendMessage(type);
        });
    }

    public handleMessage = (event: MessageEvent) => {
        if (event.origin === "https://web-dev.ventla.io" || event.origin === "https://vueadmin-dev.ventla.io") {
            const { type, data } = event.data;
            const handler = this.responseHandlers.get(type);
            if (handler) {
                handler(data);
            }
        }
    };

    async GetPlatformType(): Promise<string> {
        return ("BlazorPWA");
    }
}