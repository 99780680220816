export const HamburgerIcon = () => (
  <svg
    viewBox='0 0 1700 1750'
    xmlns='http://www.w3.org/2000/svg'
    style={{ cursor: 'pointer', margin: '-3px' }}
    width={30}
    height={40}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1664 1344v128q0 26-19 45t-45 19H192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19H192q-26 0-45-19t-19-45V832q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19H192q-26 0-45-19t-19-45V320q0-26 19-45t45-19h1408q26 0 45 19t19 45z'
      fill='white'
    />
  </svg>
);
