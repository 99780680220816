import Interop from "./interfaces/Interop";
import { ThemeContextProvider } from "./context";
import App from "./App";
import { useEffect, useState } from "react";
import { ClientCredential } from "./models/ClientCredential";
import { ApplicationColor } from "./models/ApplicationColor";
import { ChatPageNavigation } from "./models/ChatPageNavigation";
import { ChannelProvider } from '../src/components/ChannelContext/ChannelContext';


type BootComponent = {
  apiKey: string;
  targetOrigin: string;
  interop: Interop;
};

const BootComponent = (props: BootComponent) => {
  const { apiKey, targetOrigin, interop } = props;
  const [credentials, setCredentials] = useState<ClientCredential | null>(null);
  const [colors, setColors] = useState<ApplicationColor | null>(null);
  const [pageType, setPageType] = useState<ChatPageNavigation | null>(null);
  const [fontSize, setFontSize] = useState<Number | null>(null);
  const [platformTypes, setPlatformType] = useState<string | null>(null);

  useEffect(() => {

    async function getPlatformType() {
      const platformType = await interop.GetPlatformType();
      return platformType;
    }

    async function getCredentials() {
      const credentials = await interop.GetCredentials();
      return credentials;
    }

    async function getColors() {
      const colors = await interop.GetColors();
      return colors;
    }

    async function getPageType() {
      const pageType = await interop.GetPageType();
      return pageType;
    }

    async function getFontSize() {
      const fontSize = await interop.GetFontSize();
      return fontSize;
    }
    
    getPlatformType().then((val) => {
      setPlatformType(val);
    });

    getCredentials().then((val) => {
      setCredentials(val);
    });

  
    getColors().then(val => {
      setColors(val);
    });

    getPageType().then(val => setPageType(val));
    
    getFontSize().then(val => setFontSize(val));
  
  }, []);

  return (
    <>
      {credentials && colors && pageType && fontSize && (
        <ThemeContextProvider targetOrigin={targetOrigin}>
          <ChannelProvider>
            <App
              apiKey={apiKey}
              credentials={credentials}
              targetOrigin={targetOrigin}
              colors={colors}
              pageType={pageType}
              interop={interop}
              fontSize={fontSize}
              platformTypes ={platformTypes!!}
            />
          </ChannelProvider>
        </ThemeContextProvider>
      )}
    </>
  );
};

export default BootComponent;
