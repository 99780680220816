
export const ChatMessage = ({ isBlocked, isAlreadyMember, isTyping, latestMessage }
  : { isBlocked: Boolean, isAlreadyMember: Boolean, isTyping: Boolean, latestMessage: any }) => {

    let displayMessage;

  if (isBlocked) {
    displayMessage = 'Unable to chat';
  } else {
    displayMessage = isAlreadyMember
      ? (isTyping ? 'typing....' : latestMessage)
      : 'click here to join the channel';
  }

  return (
    <div>
      {displayMessage}
    </div>
  );
};

export default ChatMessage;
