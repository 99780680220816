import React from 'react';
import { useChatContext } from 'stream-chat-react';

import { HamburgerIcon } from '../../assets';

import type { StreamChatGenerics } from '../../types';

type Props = {
  onCreateChannel?: () => void;
};

const MessagingChannelListHeader = React.memo((props: Props) => {
  const { onCreateChannel } = props;

  const { client } = useChatContext<StreamChatGenerics>();

  return (
      <div className='messaging__channel-list__header'>
        <button
          className={`messaging__channel-list__header__button`}
          onClick={onCreateChannel}>
          <HamburgerIcon />
        </button>
        {/* <Avatar image={image} name={name} size={40} /> */}
        <div className={`messaging__channel-list__header__name`}>Messaging</div>
      </div>
  );
});

export default React.memo(MessagingChannelListHeader);
