import { ChannelResponse, logChatPromiseExecution, StreamChat } from 'stream-chat';
import {
  MessageList,
  MessageInput,
  MessageToSend,
  Window,
  useChannelActionContext,
  Thread,
  useChatContext,
} from 'stream-chat-react';

import { MessagingChannelHeader } from '../../components';
import { useGiphyContext } from '../../context';
import type { StreamChatGenerics } from '../../types';
import "./ChannelInner.css"
import { useEffect, useState } from 'react';
import HybridWebView from '../../hybridWebView/HybridWebView';
import Interop from '../../interfaces/Interop';
import CustomMessageRenderer from '../../CustomMessageList/CustomMessageRenderer';


function getTextArea() {
  const textArea = document.querySelector("textarea.rta__textarea.str-chat__textarea__textarea.str-chat__message-textarea") as HTMLElement;
  return textArea
}

function onTextareaInputKey(this: HTMLElement) {
  const defaultHeight = getTextAreaDefaultHeight(this);
  const maxHeight = parseInt(getTextAreaMaxDefaultHeight(this));

  this.style.height = defaultHeight + "px";
  if (this.scrollHeight < maxHeight) {
    this.style.overflowY = 'hidden';
    this.style.height = this.scrollHeight + "px";
  }
  else {
    this.style.overflowY = 'auto';
    this.style.height = maxHeight + 'px';
  }
}

function makeTextAreaHeightDynamic(textArea: HTMLElement) {
  textArea.style.removeProperty('height');
  textArea.style.height = textArea.scrollHeight + "px";
  textArea.style.overflowY = 'hidden';
}

function getTextAreaDefaultHeight(textArea: HTMLElement) {
  const computedStyle = getComputedStyle(textArea);
  const value = computedStyle.getPropertyValue("--str-chat__textarea-height");
  return value;
}

function getTextAreaMaxDefaultHeight(textArea: HTMLElement) {
  const computedStyle = getComputedStyle(textArea);
  const value = computedStyle.getPropertyValue("--str-chat__textarea-max-height");
  return value;
}

function resetTextAreaHeight(textArea: HTMLElement) {
  const defaultHeight = getTextAreaDefaultHeight(textArea);
  const height = parseInt(defaultHeight) + 2;
  textArea.style.height = height + 'px';
  textArea.style.overflowY = 'hidden';
}

function shouldSubmit(event: KeyboardEvent) {
  return HybridWebView.IsWebView() === false && event.key === 'Enter';
}

function addKeyboardObserver() {

  if (window.visualViewport !== null) {
    window.visualViewport.onresize = _ => {

      const MINIMUM_KEYBOARD_HEIGHT = 300;

      if (window.visualViewport && ((window.visualViewport.height + MINIMUM_KEYBOARD_HEIGHT) < window.screen.height)) {
        // On keyboard open.
        const listNode = document.querySelector("div.str-chat__list") as HTMLElement;

        const ulNode = document.querySelector("ul.str-chat__ul") as HTMLElement;

        if (ulNode.scrollHeight > listNode.clientHeight) {
          const allListElements = document.querySelectorAll("li.str-chat__li");

          if (allListElements.length != 0) {
            //value = `${listNode.scrollHeight - listNode.clientHeight - 8} < ${listNode.scrollTop}`;

            const lastListElement = allListElements[allListElements.length - 1];
            lastListElement.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
      else {
        // On keyboard close.
      }
    }
  }
}

function removeKeyboardObserver() {
  if (window.visualViewport !== null) {
    window.visualViewport.onresize = null;
  }
}
function showHideShareMediaIcon(value: boolean) {
  const shareMediaIcon = document.querySelector("div.str-chat__file-input-container") as HTMLElement;
  if (shareMediaIcon != null) {
    if (value) {
      shareMediaIcon.style.display = "none";
    }
    else {
      shareMediaIcon.style.display = "flex ";
    }
  }
}

export type ChannelInnerProps = {
  toggleMobile: () => void;
  interop: Interop;
  theme: string;
  platformType:string;
};

const ChannelInner = (props: ChannelInnerProps) => {
  const { theme, interop,platformType, toggleMobile } = props;
  const { giphyState, setGiphyState } = useGiphyContext();
  const { channel, client } = useChatContext<StreamChatGenerics>();

  const { sendMessage, markRead } = useChannelActionContext<StreamChatGenerics>();
  const [isBlocked, setBlocked] = useState<boolean>(false);
  const [isMuted, setMuted] = useState<boolean>(false);
  const [isMentionEnabled, setMentionEnabled] = useState<boolean>(false);
  const [isBlockIconVisible, setHideBlockIcon] = useState(false);


  const overrideSubmitHandler = (message: MessageToSend<StreamChatGenerics>) => {

    let updatedMessage;

    if (message.attachments?.length && message.text?.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '');
      updatedMessage = { ...message, text: updatedText };
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = { ...message, text: updatedText };
    }

    if (sendMessage) {

      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
            ...parentMessage,
            // created_at: parentMessage.created_at?.toString(),
            // pinned_at: parentMessage.pinned_at?.toString(),
            // updated_at: parentMessage.updated_at?.toString(),
          }
          : undefined,
      };
      markRead();
      const textArea = getTextArea();
      resetTextAreaHeight(textArea);
      textArea.focus();
      const sendMessagePromise = sendMessage(messageToSend);
      logChatPromiseExecution(sendMessagePromise, 'send message');
    }
    setGiphyState(false);
  };
  useEffect(() => {

    if (channel != null) {
      var isAlreadyMember = false;
      const members = Object.values(channel.state.members).filter(
        ({ user }) => user?.id !== client.userID,
      );

      if (channel.type == "public_with_membership") {
        if (members.length != 0) {
          for (let i = 0; i < members.length; i++) {
            if (members[i].user_id === client.userID) {
              isAlreadyMember = true;
              break;
            }
          }
        }
        if (!isAlreadyMember) {
          channel.addMembers([{ user_id: client?.user?.id!!, channel_role: "channel_member" }])
        }
      }
      else if (channel.type == "messaging") {

        var data = (channel.data) as any;
        if (data != null && data != undefined && data.frozen != undefined) {
          setBlocked(data.frozen)
          setHideBlockIcon(data.frozen)
          showHideShareMediaIcon(data.frozen)
        }

        channel.on('all', (event: any) => {
          if (event.type === 'all') {
            let value = false;
            if (event.text === "user blocked") {
              if (event.user.id === members[0].user_id!!) {
                setHideBlockIcon(true)
              }
              value = true
            } else {
              setHideBlockIcon(false)
            }
            setBlocked(value);
            showHideShareMediaIcon(value)
          }
        });
      }
      else if (channel.type == "public_without_membership") {
        setMentionEnabled(true);
      } else {
        setMentionEnabled(false);
      }

      var mutedStatus = channel.muteStatus();
      setMuted(mutedStatus.muted);
      interop.PushHandle(channel.cid);
    }
  }, []);



  (window as any).PerformBackAction = () => {
    toggleMobile();
  };
  const UpdateMutedUnMutedState = (value: boolean) => {
    setMuted(value);
  }
  const UpdateBlockUnblockState = (value: boolean) => {
    setBlocked(value);
    showHideShareMediaIcon(value)
  }

  const actions = ['delete', 'edit', 'markUnread', 'mute', 'react', 'reply'];

  useEffect(() => {
    addKeyboardObserver();

    const textArea = getTextArea();

    if (textArea != null && textArea != undefined) {
      makeTextAreaHeightDynamic(textArea);
      textArea.addEventListener('input', onTextareaInputKey, false);
    }
    return () => {
      removeKeyboardObserver();
      textArea.removeEventListener('input', onTextareaInputKey);
    };
  }, []);

  return (
    <>
      <Window>
        <MessagingChannelHeader theme={theme} toggleMobile={toggleMobile} isBlocked={isBlocked} isMuted={isMuted}
          isBlockIconVisible={isBlockIconVisible}
          UpdateBlockUnblockState={UpdateBlockUnblockState} UpdateMutedUnMutedState={UpdateMutedUnMutedState} platformType={platformType}/>

        <MessageList messageActions={actions} closeReactionSelectorOnClick={true} Message={CustomMessageRenderer}/>

        <MessageInput
          disableMentions={isMentionEnabled}
          shouldSubmit={shouldSubmit}
          overrideSubmitHandler={overrideSubmitHandler}
          disabled={isBlocked}
          hideSendButton={isBlocked}
          additionalTextareaProps={{
            onPaste: undefined,
            placeholder: isBlocked ? "Blocked" : "Type your message..."
          }}
        />


      </Window>
      <Thread Message={CustomMessageRenderer}/>
    </>
  );
};

export default ChannelInner;
