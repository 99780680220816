import { DateSeparator, type DateSeparatorProps } from 'stream-chat-react';

const DateSeparatorCustom = (props: DateSeparatorProps) => {

    const { date } = props

    const getDay = (day: Number) => {
        switch (day) {
            case 1:
                return "Monday";
            case 2:
                return "Tuesday";
            case 3:
                return "Wednesday";
            case 4:
                return "Thursday";
            case 5:
                return "Friday";
            case 6:
                return "Saturday";
            case 7:
                return "Sunday";
            default:
                return "";
        }
    }

    function formatDate(date: Date) {
        // Friday, 14 Jun, 2024
        
        const day = date.getDate();
        const dayofWeek = getDay(date.getDay());
        const monthName = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        return `${dayofWeek}, ${day} ${monthName}, ${year}`;
    }

    return (
        <DateSeparator formatDate={formatDate} date={date} position='center' />
    );
}

export default DateSeparatorCustom;