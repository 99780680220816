import './MessagingChannelPreview.css';
import {
  ChannelPreviewUIComponentProps,
  ChatContextValue,
  useChatContext,
} from 'stream-chat-react';
import AvatarChannelView from '../AvatarChannelView/AvatarChannelView';

import { useEffect, useState, type MouseEventHandler } from 'react';
import type { Channel, ChannelMemberResponse } from 'stream-chat';
import type { StreamChatGenerics } from '../../types';
import { ChatPageType } from '../../enums/ChatPageType';
import ChatMessage from '../ChatMessage/ChatMessage';
import { useChannelContext } from '../ChannelContext/ChannelContext'; // Adjust the import path accordingly
import {useBlockUnblockContext} from '../../context/BlockUnblock';

const getTimeStamp = (dateString: string) => {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  let half = 'AM';

  if (hours > 12) {
    hours -= 12;
    half = 'PM';
  }

  if (hours === 0) hours = 12; // Handle midnight case
  if (minutes.toString().length === 1) {
    minutes = `0${minutes}`; // Ensure two-digit minutes
  }

  return `${hours}:${minutes} ${half}`;
};

const formatMessageDate = (dateString: string) => {
  if (!dateString) return ''; // Check for undefined or empty string
  const messageDate = new Date(dateString);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (messageDate.toDateString() === today.toDateString()) {
    return getTimeStamp(dateString); // Use the existing getTimeStamp function for today's messages
  } else if (messageDate.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else {
    return messageDate.toLocaleDateString(); // Format it as a short date for older messages
  }
};

const getChannelName = (members: ChannelMemberResponse[]) => {
  const defaultName = 'Johnny Blaze';

  if (!members.length || members.length === 1) {
    return members[0]?.user?.name || members[0]?.user?.id;
  }
  return `${members[0]?.user?.name || members[0]?.user?.id}, ${members[1]?.user?.name || members[0]?.user?.id}`;
};

type MessagingChannelPreviewProps = ChannelPreviewUIComponentProps & {
  channel: Channel;
  onClick: MouseEventHandler;
  setActiveChannel?: ChatContextValue['setActiveChannel'];
};

const MessagingChannelPreview = (props: MessagingChannelPreviewProps) => {
  const { channel, setActiveChannel, onClick, latestMessage } = props;
  const { channel: activeChannel, client } = useChatContext<StreamChatGenerics>();
  const [isTyping, setTyping] = useState<boolean>(false);
  const [isAlreadyMember, setAlreadyMember] = useState<boolean>(true);
  const [isblocked, setBlocked] = useState<boolean>(false);
  const [isMuted, setMuted] = useState<boolean>(false);

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user?.id !== client.userID,
  );
  const { setSelectedChannel } = useChannelContext(); // Get context
  const { blockedUsers} = useBlockUnblockContext(); // Use the context
  
  useEffect(() => {
    const userId = members[0]?.user?.id;
  
    if (userId) {
      setBlocked(blockedUsers[userId] || false); // Check if the user is blocked
    }
  }, [blockedUsers]);
  
  

  useEffect(() => {

    channel.on(_ => {

      if (channel.type != "messaging") {
        if (channel.muteStatus().muted) {
          var data = channel.state.read[client.userID!!]
          if (data.unread_messages > 0) {
            setMuted(true)
          }
        }

      }
    });

    if (channel.type == "public_with_membership") {
      const alreadyChannelMembers = Object.values(channel.state.members || {}).filter(
        (member) => member.user?.id === client?.user?.id,
      );
      if (alreadyChannelMembers.length > 0) {
        setAlreadyMember(true);
      } else {
        setAlreadyMember(false);
      };
    }

    else if (channel.type == "messaging") {
      if (channel.data?.blocked === true) {
        setBlocked(true)
      } else {
        setBlocked(false);
        //         if (channel.data != null && channel.data != undefined && channel.data.frozen != undefined) {
        //   setBlocked(channel.data?.frozen)
        // }
      }
    }
  }, []);

  let unreadCount = 0;

  var data = channel.state.read[client.userID!!]
  if (data.unread_messages > 0) {
    unreadCount = data.unread_messages
  }


  channel.on('typing.start', event => {
    console.log("dlkjd" + event);
    if (event.user?.id != client?.user?.id)
      setTyping(true)
  })

  channel.on('typing.stop', event => {
    if (event.user?.id != client?.user?.id)
      setTyping(false)
  })
  const ConfermationPopUp = () => {
    const userConfirmed = window.confirm("You have blocked the participant. Do you want to unblock?");
    if (userConfirmed) {
      client.unBlockUser(members[0]?.user?.id!!).then(_ => {
        setBlocked(false)
        channel.updatePartial({ set: { frozen: false } });
      }
      );
    }
  };
  // Determine if this channel is the active one
  const isActive = activeChannel?.id === channel.id;

  return (
    <div
      className={`channel-preview__container ${isActive ? 'active' : 'inactive'}`}
      onClick={(e) => {
        e.preventDefault();
        if (!isblocked) {
          onClick(e);
          setActiveChannel?.(channel);
          setSelectedChannel(channel); // Set the selected channel in context
        } else {
          ConfermationPopUp()
        }
      }}
    >
      <AvatarChannelView data={channel} pageType={ChatPageType.ChannelListing} />

      <div className='channel-preview__content-wrapper'>
        <div className='channel-preview__content-top'>
          <p className='channel-preview__content-name'>
            {channel.data?.name || getChannelName(members)}
          </p>
          <p className='channel-preview__content-time'>{formatMessageDate(channel.state.last_message_at?.toString()!!)}</p>
        </div>
        <div className={

          `${unreadCount || isTyping || isMuted ?
            'channel-preview__content-message-unread' : 'channel-preview__content-message'}`}>
          {
            <ChatMessage isBlocked={isblocked} isAlreadyMember={isAlreadyMember} isTyping={isTyping}
              latestMessage={latestMessage} />
          }

        </div>
      </div>
    </div>
  );
};

export default MessagingChannelPreview;
