import { ChatPageType } from "../enums/ChatPageType";
import { ModuleType } from "../enums/ModuleType";

export class ChatPageNavigation
{
    ChatPageType: ChatPageType;

    EntityType: ModuleType;

    EntityId: string;
    
    ChannelHashId: string;

    constructor(chatPageType: ChatPageType, entityType: ModuleType,entityId:string,ChannelHashId: string) {
        this.ChatPageType = chatPageType;
        this.EntityType = entityType;
        this.EntityId = entityId;
        this.ChannelHashId = ChannelHashId;
    }
}

