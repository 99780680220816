import React, { useEffect, useRef, useState } from 'react';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';
import './MessagingChannelHeader.css';
import { BlockUserIcon, UnBlockUserIcon, ChevronLeft, UnmuteChannelIcon, MuteChannelIcon } from '../../assets';
import { AvatarChannelView } from '../';

import type { StreamChatGenerics } from '../../types';
import { ChatPageType } from '../../enums/ChatPageType';
import {useBlockUnblockContext} from '../../context/BlockUnblock';

type Props = {
  theme: string;
  toggleMobile: () => void;
  isBlocked: boolean;
  isMuted: boolean;
  isBlockIconVisible: boolean;
  platformType : string;
  UpdateBlockUnblockState: (value: boolean) => void;
  UpdateMutedUnMutedState: (value: boolean) => void;

};


const MessagingChannelHeader = (props: Props) => {
  const { theme, platformType, toggleMobile, UpdateBlockUnblockState, UpdateMutedUnMutedState } = props;
  const { client, setActiveChannel } = useChatContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext();
  const [channelName, setChannelName] = useState(channel.data?.name || '');
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  var isBlocked = props.isBlocked;
  var isMuted = props.isMuted;
  var isBlockIconVisible = props.isBlockIconVisible;

  const { toggleBlock } = useBlockUnblockContext(); // Get context

  const members = Object.values(channel.state.members || {}).filter(
    (member) => member.user?.id !== client?.user?.id,
  );

  const PerformBackNavigation = () => {
    toggleMobile()
    setActiveChannel(undefined);
  }

  const blockUnblockUser = async (blockedUserId: string) => {
    if (isBlocked) {
      try {
        await client.unBlockUser(blockedUserId,client?.user?.id).then(
          channeldata => 
            {
              console.log("jkkj"+channeldata);
              UpdateBlockUnblockState(false)
            }
        );
    
        await channel.updatePartial({set: {frozen: false}});
        isBlocked = false;
        toggleBlock(blockedUserId );
       await channel.updatePartial({set: {frozen: false}});
        await channel.sendEvent({ type: 'all', text: 'user unblocked' });
      } catch (err) {
        console.log('Error blocking user:', err);
      }

    } else {
      try {

       
        await client.blockUser(blockedUserId,client?.user?.id).then(channeldata => 
          {
            console.log("jkkj"+channeldata);
            
            UpdateBlockUnblockState(true)
          }
         );
        await channel.sendEvent({ type: 'all', text: 'user blocked'});
       await channel.updatePartial({set: {frozen: true}});
        isBlocked = true;
        toggleBlock(blockedUserId);
      } catch (err) {
        console.log('Error blocking user:', err);
      }
    }
  }

  const muteUnmuteChannel = () => {

    if (isMuted) {
      channel.unmute({ user_id: client?.user?.id }).then(_ => UpdateMutedUnMutedState(false));
      alert("unmute");
      isMuted = false;

    } else {
      channel.mute({ user_id: client?.user?.id }).then(_ => UpdateMutedUnMutedState(true));
      alert("mute");
      isMuted = true;
    }
  }
  const updateChannel = async () => {
    if (channelName && channelName !== channel.data?.name) {
      await channel.update(
        { name: channelName },
        { text: `Channel name changed to ${channelName}` },
      );
    }

    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef?.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (!channelName) {
      setTitle(
        members.map((member) => member.user?.name || member.user?.id || 'Unnamed User').join(', '),
      );
    }
  }, [channelName, members]);

  const EditHeader = () => (
    <form
      style={{ flex: 1 }}
      onSubmit={(event) => {
        event.preventDefault();
        inputRef?.current?.blur();
      }}
    >
      <input
        autoFocus
        className='channel-header__edit-input'
        onBlur={updateChannel}
        onChange={(event) => setChannelName(event.target.value)}
        placeholder='Type a new name for the chat'
        ref={inputRef}
        value={channelName}
      />
    </form>
  );

  (window as any).PerformBackAction = () => {
    PerformBackNavigation();
  };

  return (
    <div className='messaging__channel-header'>
      <div role="button" aria-label="Back button" id='mobile-nav-icon' className={`${theme}`} style={{ display: platformType == 'BlazorPWA' ? 'none' : 'block' }}
        onClick={PerformBackNavigation}>
        <ChevronLeft />
      </div>

      <AvatarChannelView data={channel} pageType={ChatPageType.ChannelDetail} />
      <div className='channel-header__name'>{channelName || title}</div>
      { }

      <div className='messaging__channel-header__right'
        onClick={() => {
          if (channel.type != "messaging") {
            muteUnmuteChannel();
          } else {
            blockUnblockUser(members[0]?.user!.id);
          }

        }}>
       
        {
        channel.type==="messaging"? isBlockIconVisible?undefined:
        isBlocked ? <UnBlockUserIcon /> : <BlockUserIcon /> 
        : channel.type==="public_without_membership"?undefined:
        isMuted? <UnmuteChannelIcon /> : <MuteChannelIcon /> 
        }

      </div>

      {/* {!isEditing ? (
        <div className='channel-header__name'>{channelName || title}</div>
      ) : (
        <EditHeader />
      )}
      <div className='messaging__channel-header__right'>
        <TypingIndicator />
        {channelName !== 'Social Demo' &&
          (!isEditing ? <ChannelInfoIcon {...{ isEditing, setIsEditing }} /> : <ChannelSaveIcon />)}
      </div> */}
    </div>
  );
};

export default React.memo(MessagingChannelHeader);
