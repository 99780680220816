export const UnmuteChannelIcon = () => (
  <svg width='20'
  height='20'
  viewBox='0 0 20 20'
  fill='none'
   xmlns='http://www.w3.org/2000/svg'>
<path d="M14.5024 6.40096C14.5024 4.96853 13.9334 3.59478 12.9205 2.5819C11.9076 1.56903 10.5339 1 9.10144 1C7.66901 1 6.29526 1.56903 5.28238 2.5819C4.26951 3.59478 3.70048 4.96853 3.70048 6.40096C3.70048 12.7021 1 14.5024 1 14.5024H17.2029C17.2029 14.5024 14.5024 12.7021 14.5024 6.40096Z"
 stroke="white"  strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
<path d="M10.6587 18.103C10.5005 18.3758 10.2733 18.6023 10 18.7597C9.72672 18.9171 9.41686 19 9.10146 19C8.78607 19 8.47621 18.9171 8.2029 18.7597C7.9296 18.6023 7.70245 18.3758 7.54419 18.103"
 stroke="white"  strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
</svg>

);

